import React, { Component } from "react"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"
import ImageFooterSvg from "../images/frontend/footer.svg"
import ImageFooterImg from "../images/frontend/footer-img.png"

class Herofooter extends Component {
  constructor(props) {
    super(props)
    //this.handleClick = this.handleClick.bind(this)
  }

  // function to handle the click
  // handleClick() {
  //   //gtm tracking//tag manager
  //   if (typeof window !== "undefined") {
  //     var dataLayer = window.dataLayer || []
  //     dataLayer.push({
  //       event: "getStartedClick",
  //       path: window.location.pathname,
  //       host: window.location.host
  //     })
  //   }
  // }

  render() {
    return (
      <section className="cta-hero-footer">
        <div className="footer-bg">
          <img src={ImageFooterSvg} alt="presentation" aria-hidden="true" />
        </div>
        <div className="content-wrapper">
          <div className="cta-hero-footer__illo">
            <img
              src={ImageFooterImg}
              alt="Illustration of developers talking"
            />
          </div>
          <div className="cta-hero-footer__text">
            <span className="light" aria-level="2">
              <FormattedMessage id="LANDING.TRY_TALEPNET_HEAD" />
            </span>
            <p className="light">
              <FormattedMessage id="LANDING.TRY_TALEPNET_DESC" />
            </p>

            <a
              href="https://app.talepnet.com/pages/auth/signup"
              id="ga-get-started-button"
              className="cta-button cta-button--white cta-button--text-sky-blue gc-analytics-event"
            >
              <FormattedMessage id="LANDING.GET_STARTED_FREE" />
            </a>
          </div>
        </div>
      </section>
    )
  }
}

export default Herofooter
